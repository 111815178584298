import React, { useState, useCallback, useEffect } from 'react';
import MapComponent from './components/MapComponent';
import SidebarComponent from './components/SidebarComponent';
import { useAttractions } from './hooks/useAttractions';
import { useRouteOptimization } from './hooks/useRouteOptimization';
import { countries } from './config/constants';

function App() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedSource, setSelectedSource] = useState('Opentripmap');
  const [customFile, setCustomFile] = useState(null);
  const [shouldResetAttractions, setShouldResetAttractions] = useState(false);

  const {
    groupedAttractions,
    selectedAttractions,
    showAttractionsList,
    isLoading,
    loadAttractions,
    handleGroupSelect,
    handleAttractionChange,
    resetAttractions
  } = useAttractions(selectedCountry, selectedActivities, selectedSource, customFile, shouldResetAttractions);

  const { optimizeRoute } = useRouteOptimization();

  useEffect(() => {
    if (shouldResetAttractions) {
      resetAttractions();
      setShouldResetAttractions(false);
    }
  }, [shouldResetAttractions, resetAttractions]);

  const handleCountryChange = useCallback((event) => {
    setSelectedCountry(event.target.value);
    setSelectedActivities([]);
    setCustomFile(null);
    setShouldResetAttractions(true);
  }, []);

  const handleActivityChange = useCallback((activityId) => {
    setSelectedActivities(prev => 
      prev.includes(activityId) 
        ? prev.filter(a => a !== activityId) 
        : [...prev, activityId]
    );
  }, []);

  const handleSourceChange = useCallback((newSource) => {
    setSelectedSource(newSource);
    if (newSource === 'Custom File') {
      setCustomFile(null);
      setShouldResetAttractions(true);
    }
  }, []);

  const handleFileChange = useCallback((file) => {
    setCustomFile(file);
    setShouldResetAttractions(true);
  }, []);

  return (
    <div style={{display: 'flex', height: '100vh'}}>
      <SidebarComponent
        selectedCountry={selectedCountry}
        selectedActivities={selectedActivities}
        selectedSource={selectedSource}
        groupedAttractions={groupedAttractions}
        selectedAttractions={selectedAttractions}
        showAttractionsList={showAttractionsList}
        isLoading={isLoading}
        countries={countries}
        onCountryChange={handleCountryChange}
        onActivityChange={handleActivityChange}
        onSourceChange={handleSourceChange}
        onFileChange={handleFileChange}
        onGroupSelect={handleGroupSelect}
        onAttractionChange={handleAttractionChange}
        loadAttractions={loadAttractions}
        optimizeRoute={optimizeRoute}
      />
      <MapComponent
        selectedCountry={selectedCountry}
        selectedAttractions={selectedAttractions}
        optimizeRoute={optimizeRoute}
      />
    </div>
  );
}

export default App;