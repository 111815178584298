import React, { useRef, useState, useEffect } from 'react';
import { activities, dataSources } from '../config/constants';
import { generateGPX, downloadGPX } from '../utils/gpxUtils';
import { downloadJSON } from '../utils/jsonUtils';

function SidebarComponent({
  selectedCountry,
  selectedActivities,
  selectedSource,
  groupedAttractions,
  selectedAttractions,
  showAttractionsList,
  isLoading,
  countries,
  onCountryChange,
  onActivityChange,
  onSourceChange,
  onFileChange,
  onGroupSelect,
  onAttractionChange,
  loadAttractions,
  optimizeRoute
}) {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [expandedActivities, setExpandedActivities] = useState({});

  useEffect(() => {
    setExpandedActivities({});
  }, [selectedCountry]);

  const handleSourceChange = (event) => {
    const newSource = event.target.value;
    onSourceChange(newSource);
    if (newSource === 'Custom File') {
      fileInputRef.current.click();
    } else {
      setSelectedFileName('');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      onFileChange(file);
    }
  };

  const handleLoadAttractions = () => {
    if (selectedSource === 'Custom File' && !selectedFileName) {
      alert('Per favore, seleziona un file prima di caricare le attrazioni.');
      return;
    }
    loadAttractions();
  };

  const handleDownloadGPX = () => {
    const optimizedRoute = optimizeRoute(selectedAttractions);
    const gpxContent = generateGPX(optimizedRoute);
    downloadGPX(gpxContent);
  };

  const toggleActivity = (activity) => {
    setExpandedActivities(prev => ({
      ...prev,
      [activity]: !prev[activity]
    }));
  };

  const handleDownloadSelectedAttractions = () => {
    const attractionsToDownload = selectedAttractions.reduce((acc, attraction) => {
      if (!acc[attraction.activity]) {
        acc[attraction.activity] = [];
      }
      acc[attraction.activity].push({
        name: attraction.name,
        lat: attraction.lat,
        lon: attraction.lon,
        url: attraction.url
      });
      return acc;
    }, {});

    downloadJSON(attractionsToDownload, 'selected_attractions.json');
  };

  return (
    <div style={{width: '300px', padding: '20px', borderRight: '1px solid #ccc', overflowY: 'auto'}}>
      <h1>TripPlanner</h1>
      <p>Benvenuto nel tuo pianificatore di viaggi!</p>
      
      <h2>1. Seleziona una nazione:</h2>
      <select 
        onChange={onCountryChange} 
        value={selectedCountry} 
        style={{width: '100%', marginBottom: '20px', padding: '5px'}}
      >
        <option value="">Seleziona una nazione</option>
        {Object.entries(countries).map(([value, label]) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
      
      {selectedCountry && (
        <>
          <h2>2. Sorgente dati:</h2>
          <select 
            onChange={handleSourceChange}
            value={selectedSource} 
            style={{width: '100%', marginBottom: '20px', padding: '5px'}}
          >
            {dataSources.map(source => (
              <option key={source} value={source}>{source}</option>
            ))}
          </select>
          <input 
            type="file" 
            ref={fileInputRef} 
            style={{display: 'none'}} 
            onChange={handleFileChange}
            accept=".json"
          />
          
          {selectedSource === 'Custom File' && (
            <div style={{marginBottom: '20px'}}>
              <button 
                onClick={() => fileInputRef.current.click()} 
                style={{
                  width: '100%', 
                  padding: '10px', 
                  backgroundColor: '#4CAF50', 
                  color: 'white', 
                  border: 'none', 
                  borderRadius: '5px', 
                  cursor: 'pointer'
                }}
              >
                {selectedFileName ? 'Cambia File' : 'Seleziona File'}
              </button>
              {selectedFileName && <p>File selezionato: {selectedFileName}</p>}
            </div>
          )}

          {selectedSource === 'Opentripmap' && (
            <>
              <h3>Seleziona le attività:</h3>
              {activities.map(activity => (
                <div key={activity.id} style={{marginBottom: '10px'}}>
                  <label style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type="checkbox"
                      checked={selectedActivities.includes(activity.id)}
                      onChange={() => onActivityChange(activity.id)}
                      style={{marginRight: '10px'}}
                    />
                    {activity.label}
                  </label>
                </div>
              ))}
            </>
          )}

          <button 
            onClick={handleLoadAttractions} 
            style={{
              width: '100%', 
              marginTop: '20px', 
              padding: '10px', 
              backgroundColor: '#4CAF50', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer'
            }}
            disabled={isLoading || (selectedSource === 'Opentripmap' && selectedActivities.length === 0)}
          >
            {isLoading ? 'Caricamento in corso...' : 'Carica Attrazioni'}
          </button>
        </>
      )}
      
      {showAttractionsList && (
        <>
          <h2>Seleziona le attrazioni:</h2>
          <div style={{maxHeight: '300px', overflowY: 'auto', marginBottom: '20px'}}>
            {Object.entries(groupedAttractions).map(([activity, attractions]) => (
              <div key={activity}>
                <div 
                  style={{display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer'}}
                  onClick={() => toggleActivity(activity)}
                >
                  <input
                    type="checkbox"
                    checked={selectedAttractions.some(attr => attr.activity === activity)}
                    onChange={(e) => {
                      e.stopPropagation();
                      onGroupSelect(activity, e.target.checked);
                    }}
                    style={{marginRight: '10px'}}
                  />
                  <span style={{marginRight: '5px'}}>
                    {expandedActivities[activity] ? '▼' : '▶'}
                  </span>
                  <div style={{fontWeight: 'bold'}}>
                    {activity} ({attractions.length})
                  </div>
                </div>
                {expandedActivities[activity] && attractions.map(attraction => (
                  <div key={attraction.name} style={{marginLeft: '20px', marginBottom: '5px'}}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                      <input
                        type="checkbox"
                        checked={selectedAttractions.some(a => a.name === attraction.name && a.activity === attraction.activity)}
                        onChange={() => onAttractionChange(attraction)}
                        style={{marginRight: '10px'}}
                      />
                      <a href={attraction.url} target="_blank" rel="noopener noreferrer">{attraction.name}</a>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button 
            onClick={() => onGroupSelect('all', selectedAttractions.length === 0)}
            style={{
              width: '100%', 
              marginTop: '20px', 
              padding: '10px', 
              backgroundColor: '#4CAF50', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer'
            }}
          >
            {selectedAttractions.length === 0 ? 'Seleziona Tutte' : 'Deseleziona Tutte'}
          </button>
          <button 
            onClick={() => onGroupSelect('optimized', true)}
            style={{
              width: '100%', 
              marginTop: '20px', 
              padding: '10px', 
              backgroundColor: '#4CAF50', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer'
            }}
            disabled={selectedAttractions.length < 2}
          >
            Ottimizza Percorso
          </button>
          <button 
            onClick={handleDownloadGPX}
            style={{
              width: '100%', 
              marginTop: '20px', 
              padding: '10px', 
              backgroundColor: '#4CAF50', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer'
            }}
            disabled={selectedAttractions.length === 0}
          >
            Scarica Percorso GPX
          </button>
          <button 
            onClick={handleDownloadSelectedAttractions}
            style={{
              width: '100%', 
              marginTop: '20px', 
              padding: '10px', 
              backgroundColor: '#4CAF50', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer'
            }}
            disabled={selectedAttractions.length === 0}
          >
            Scarica Attrazioni Selezionate
          </button>
        </>
      )}
    </div>
  );
}

export default SidebarComponent;