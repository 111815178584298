export function getCountryCenter(country) {
  const centers = {
    'ireland': { lat: 53.1424, lon: -7.6921 },
    'england': { lat: 52.3555, lon: -1.1743 },
    'france': { lat: 46.2276, lon: 2.2137 },
    'spain': { lat: 40.4637, lon: -3.7492 },
    'portugal': { lat: 39.3999, lon: -8.2245 },
    'usa': { lat: 39.8283, lon: -98.5795 },
    'canada': { lat: 56.1304, lon: -106.3468 },
    'italia': { lat: 41.8719, lon: 12.5674 },
    'china': { lat: 35.8617, lon: 104.1954 }
  };
  return centers[country.toLowerCase()] || { lat: 0, lon: 0 };
}

export function distance(point1, point2) {
  const R = 6371; // Raggio della Terra in km
  const dLat = (point2.lat - point1.lat) * Math.PI / 180;
  const dLon = (point2.lon - point1.lon) * Math.PI / 180;
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}

export function getCountryCode(country) {
  const countryCodes = {
   'ireland': 'ie',
    'england': 'gb',
    'france': 'fr',
    'spain': 'es',
    'portugal': 'pt',
    'usa': 'us',
    'canada': 'ca',
    'italia': 'it',
    'china': 'cn'
  };
  return countryCodes[country.toLowerCase()] || '';
}