import { API_KEY } from '../config/constants';
import { getCountryCenter, getCountryCode } from './mapUtils';


export async function fetchOpenTripMapData(country, selectedActivities) {
  const countryCode = getCountryCode(country);
  const attractions = await Promise.all(selectedActivities.flatMap(async (activity) => {
    const kinds = getKinds(activity);
    const limit = 50;
    const radius = 1000000;

    const centerCoords = getCountryCenter(country);

    const url = `https://api.opentripmap.com/0.1/en/places/radius?radius=${radius}&lon=${centerCoords.lon}&lat=${centerCoords.lat}&kinds=${kinds}&limit=${limit}&country=${countryCode}&apikey=${API_KEY}`;
    
    const response = await fetch(url);
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(data)}`);
    }
    
    if (!data.features || data.features.length === 0) {
      return [];
    }
    
    return data.features.map(feature => ({
      name: feature.properties.name || 'Nome sconosciuto',
      lat: feature.geometry.coordinates[1] || 0,
      lon: feature.geometry.coordinates[0] || 0,
      country: feature.properties.country || country,
      rate: feature.properties.rate || 0,
      activity: activity
    }));
  }));

  return attractions.flat();
}

function getKinds(activity) {
  const kindMap = {
    'nature': 'natural',
    'bicycle': 'interesting_places',
    'hiking': 'hiking',
    'landscapes': 'natural',
    'museums': 'museums',
    'pubs': 'foods',
    'water_sports': 'amusements',
    'horse_riding': 'sport'
  };
  return kindMap[activity] || '';
}
