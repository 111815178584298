import { useState, useCallback, useEffect } from 'react';
import { fetchOpenTripMapData } from '../utils/dataUtils';

export function useAttractions(selectedCountry, selectedActivities, selectedSource, customFile, shouldResetAttractions) {
  const [groupedAttractions, setGroupedAttractions] = useState({});
  const [selectedAttractions, setSelectedAttractions] = useState([]);
  const [showAttractionsList, setShowAttractionsList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetAttractions = useCallback(() => {
    setGroupedAttractions({});
    setSelectedAttractions([]);
    setShowAttractionsList(false);
  }, []);

  useEffect(() => {
    if (shouldResetAttractions) {
      resetAttractions();
    }
  }, [shouldResetAttractions, resetAttractions]);

  const groupAttractions = (attractions) => {
    return attractions.reduce((acc, attraction) => {
      if (!acc[attraction.activity]) {
        acc[attraction.activity] = [];
      }
      acc[attraction.activity].push(attraction);
      return acc;
    }, {});
  };

  const loadCustomFileData = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = JSON.parse(e.target.result);
          if (typeof content !== 'object' || content === null) {
            throw new Error('Il file JSON non contiene un oggetto valido');
          }
          const attractions = Object.entries(content).flatMap(([activity, items]) => {
            if (!Array.isArray(items)) {
              throw new Error(`L'attività "${activity}" non contiene un array di attrazioni`);
            }
            return items.map(item => {
              if (typeof item !== 'object' || item === null || !item.name || !item.lat || !item.lon) {
                throw new Error(`Formato non valido per un'attrazione in "${activity}"`);
              }
              return { ...item, activity };
            });
          });
          resolve(attractions);
        } catch (error) {
          reject(new Error(`Errore nel parsing del file JSON: ${error.message}`));
        }
      };
      reader.onerror = () => reject(new Error('Errore nella lettura del file'));
      reader.readAsText(file);
    });
  }, []);

  const loadAttractions = useCallback(async () => {
    setIsLoading(true);
    try {
      let loadedAttractions;
      switch (selectedSource) {
        case 'Custom File':
          if (!customFile) {
            throw new Error('Nessun file personalizzato selezionato.');
          }
          loadedAttractions = await loadCustomFileData(customFile);
          break;
        case 'Opentripmap':
          if (selectedActivities.length === 0) {
            throw new Error('Per favore, seleziona almeno un\'attività prima di caricare le attrazioni.');
          }
          loadedAttractions = await fetchOpenTripMapData(selectedCountry, selectedActivities);
          break;
        default:
          throw new Error('Sorgente dati non valida');
      }
      
      const grouped = groupAttractions(loadedAttractions);
      
      setGroupedAttractions(grouped);
      setShowAttractionsList(true);
      setSelectedAttractions([]); // Reset selected attractions when loading new ones
      console.log('Attrazioni caricate con successo');
    } catch (error) {
      console.error("Errore nel caricamento delle attrazioni:", error);
      alert(`Si è verificato un errore nel caricamento delle attrazioni: ${error.message}`);
      resetAttractions();
    } finally {
      setIsLoading(false);
    }
  }, [selectedSource, selectedCountry, selectedActivities, customFile, loadCustomFileData, resetAttractions]);

  const handleGroupSelect = useCallback((activity, isSelected) => {
    setSelectedAttractions(prev => {
      if (activity === 'all') {
        if (isSelected) {
          return Object.values(groupedAttractions).flat();
        } else {
          return [];
        }
      } else if (activity === 'optimized') {
        // Implement optimization logic here if needed
        return prev;
      } else {
        const groupAttractions = groupedAttractions[activity] || [];
        if (isSelected) {
          return [...prev, ...groupAttractions.filter(attr => !prev.some(a => a.name === attr.name && a.activity === attr.activity))];
        } else {
          return prev.filter(attr => attr.activity !== activity);
        }
      }
    });
  }, [groupedAttractions]);

  const handleAttractionChange = useCallback((attraction) => {
    setSelectedAttractions(prev => 
      prev.some(a => a.name === attraction.name && a.activity === attraction.activity)
        ? prev.filter(a => !(a.name === attraction.name && a.activity === attraction.activity))
        : [...prev, attraction]
    );
  }, []);

  return {
    groupedAttractions,
    selectedAttractions,
    showAttractionsList,
    isLoading,
    loadAttractions,
    handleGroupSelect,
    handleAttractionChange,
    resetAttractions
  };
}