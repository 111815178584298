export function generateGPX(route) {
  let gpx = '<?xml version="1.0" encoding="UTF-8"?>\n';
  gpx += '<gpx version="1.1" creator="TripPlanner" xmlns="http://www.topografix.com/GPX/1/1">\n';
  gpx += '  <trk>\n';
  gpx += '    <name>Percorso ottimizzato</name>\n';
  gpx += '    <trkseg>\n';

  route.forEach(point => {
    gpx += `      <trkpt lat="${point.lat}" lon="${point.lon}">\n`;
    gpx += `        <name>${point.name}</name>\n`;
    gpx += '      </trkpt>\n';
  });

  gpx += '    </trkseg>\n';
  gpx += '  </trk>\n';
  gpx += '</gpx>';

  return gpx;
}

export function downloadGPX(gpxContent, filename = 'percorso_ottimizzato.gpx') {
  const blob = new Blob([gpxContent], { type: 'application/gpx+xml' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}