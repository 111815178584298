export const API_KEY = '5ae2e3f221c38a28845f05b66c8c3697ab04f3fe5d0007823130a22a';

export const countries = {
  '': 'Seleziona una nazione',
  'ireland': 'Irlanda',
  'england': 'Inghilterra',
  'france': 'Francia',
  'spain': 'Spagna',
  'portugal': 'Portogallo',
  'usa': 'USA',
  'canada': 'Canada',
  'china': 'Cina',
  'italy': 'Italia'
};

export const activities = [
  { id: 'nature', label: 'Natura' },
  { id: 'bicycle', label: 'Bicicletta' },
  { id: 'hiking', label: 'Camminate' },
  { id: 'landscapes', label: 'Paesaggi' },
  { id: 'museums', label: 'Musei' },
  { id: 'pubs', label: 'Locali pubblici' },
  { id: 'water_sports', label: 'Sport acquatici' },
  { id: 'horse_riding', label: 'Sport equestri' }
];

export const dataSources = ['Custom File', 'Opentripmap'];