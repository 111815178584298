import { useCallback } from 'react';
import { distance } from '../utils/mapUtils';

export function useRouteOptimization() {
  const optimizeRoute = useCallback((points) => {
    const route = [points[0]];
    const unvisited = points.slice(1);

    while (unvisited.length > 0) {
      let bestDistance = Infinity;
      let bestIndex = -1;
      for (let i = 0; i < unvisited.length; i++) {
        const d = distance(route[route.length - 1], unvisited[i]);
        if (d < bestDistance) {
          bestDistance = d;
          bestIndex = i;
        }
      }
      route.push(unvisited[bestIndex]);
      unvisited.splice(bestIndex, 1);
    }

    return route;
  }, []);

  return { optimizeRoute };
}