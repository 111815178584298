import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
import { getCountryCenter } from '../utils/mapUtils';
import '../styles/CustomMarker.css';

function MapComponent({ selectedCountry, selectedAttractions, optimizeRoute }) {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markerClusterGroupRef = useRef(null);
  const routeLayerRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = L.map(mapRef.current).setView([0, 0], 2);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mapInstanceRef.current);
      markerClusterGroupRef.current = L.markerClusterGroup();
      mapInstanceRef.current.addLayer(markerClusterGroupRef.current);
    }
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current && selectedCountry) {
      const center = getCountryCenter(selectedCountry);
      mapInstanceRef.current.setView([center.lat, center.lon], 6);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (mapInstanceRef.current && markerClusterGroupRef.current) {
      // Pulisci i marker
      markerClusterGroupRef.current.clearLayers();
      
      // Pulisci la traccia GPS
      if (routeLayerRef.current) {
        mapInstanceRef.current.removeLayer(routeLayerRef.current);
        routeLayerRef.current = null;
      }

      if (selectedAttractions.length > 0) {
        const optimizedRoute = optimizeRoute(selectedAttractions);

        optimizedRoute.forEach(attraction => {
          const marker = createCustomMarker(attraction);
          marker.bindPopup(`<b>${attraction.name}</b><br>${attraction.activity}<br><a href="${attraction.url}" target="_blank">Visita il sito</a>`);
          markerClusterGroupRef.current.addLayer(marker);
        });

        // Disegna il percorso
        const routeCoordinates = optimizedRoute.map(attr => [attr.lat, attr.lon]);
        routeLayerRef.current = L.polyline(routeCoordinates, {color: 'red', weight: 3}).addTo(mapInstanceRef.current);

        mapInstanceRef.current.fitBounds(routeLayerRef.current.getBounds().pad(0.1));
      }
    }
  }, [selectedAttractions, optimizeRoute]);

  const createCustomMarker = (attraction) => {
    const iconMap = {
      natura: 'tree',
      bicicletta: 'bicycle',
      camminate: 'hiking',
      musei: 'landmark',
      locali_pubblici_caratteristici: 'beer',
      sport_acquatici: 'water',
      sport_equestri: 'horse',
      paesaggi: 'mountain',
      default: 'circle'
    };

    const icon = L.divIcon({
      html: `<i class="fas fa-${iconMap[attraction.activity] || iconMap.default}"></i>`,
      iconSize: [30, 30],
      className: 'custom-div-icon'
    });

    return L.marker([attraction.lat, attraction.lon], { icon });
  };

  return <div ref={mapRef} style={{ height: '100%', width: '100%' }} />;
}

export default MapComponent;